import Router from 'next/router';
import {create} from 'zustand';
import i18n from '../i18n';
import {Country, License} from '../interfaces/types';
import {getCookie, onboardingRoutes} from '../utils/helpers';
import {urlGlobalConfiguration, urlUpdateMe} from '../../components/utils/server-links';

import { getCountries, getLocales, getPlatformDefault, getAllLicenses, requestQuiz, requestUser, updateQuiz, completeQuiz, getAllCurrencies } from './storeActions';
import axios from 'axios';
import InstrumentType from 'pages/register/[[...instrumentType]]';

interface StoreType {
    user: { [key: string]: any } | null;
    token: string | null;
    activeQuestion: number;
    groups: any[] | null;
    quizId: number;
    errorQuiz: string;
    geoCountry: string;
    countries: Country[] | null;
    locales: any[] | null;
    currency: [] | any;
    currentLocale: [] | any;
    defaultCurrency: string | null | any;
    defaultCountry: string | null;
    defaultCountryOptions: { [key: string]: any };
    defaultLicense: any;
    countryLicenses: any;
    allLicenses: License[] | null;
    allCurrencies: any;
    errorGeneral: string;
    activeQuestionName: string;
    currentScreenName: string;
    isInsidePlatform: boolean;
    isSubmitting: boolean;
    globalConfigurations: {} | any;
    quizTemporary: {} | any;
    setUser: (tokenPredefined?:string) => void;
    setGeoCountry: (country: string) => void;
    setDefaultLicenseStore: (license: any) => void;
    setToken: (token: string) => void;
    getToken: () => string | null;
    emptyUser: () => void;
    updateQuiz: (data: { [key: string]: string }, quizId?: number | null, instrumentyTypeId?: number | null, pushGtagData?: any | null) => void;
    saveQuizOnExit: (data: { [key: string]: string }, quizId?: number | null, instrumentyTypeId?: number | null) => void;
    getQuiz: (quizId: number) => void;
    setActiveQuestion: (param?: number) => void;
    setCountriesList: () => void;
    setLocalesList: () => void;
    setCurrentLocale:(locale: any) => void;
    getNextScreen: (active: string) => any;
    getPrevScreen: (active: string) => any;
    setAllLicenses: (countryCode?: string | null) => void;
    setAllCurrencies: () => void;
    setPlatformDefault: (countryCode?: string | null) => void;
    setActiveQuestionName: (questionName: string) => any;
    setCurrentScreenName: (screenName: string) => any;
    setIsInsidePlatform: (status: boolean) => void;
    setIsSubmitting: (status: boolean) => void;
    getGlobalConfigurations: () => void;
    setQuizTemporary: (data: { [key: string]: string }, quizId?: number | null, instrumentyTypeId?: number | null) => void;
}


const useStore = create<StoreType>((set, get) => ({
    user: null,
    token: null,
    activeQuestion: 0,
    groups: [],
    errorQuiz: "",
    geoCountry: "",
    countries: null,
    locales: null,
    quizId: 0,
    currency: [],
    defaultCurrency: null,
    currentLocale: null,
    defaultCountry: null,
    defaultCountryOptions: {},
    errorGeneral: "",
    defaultLicense: null,
    countryLicenses: null,
    allLicenses: null,
    allCurrencies: null,
    activeQuestionName: '',
    currentScreenName: '',
    isInsidePlatform: false,
    isSubmitting: false,
    globalConfigurations: null,
    quizTemporary: {},
    setGeoCountry: async (country: string) => {
        set({ geoCountry: country });
    },
    setUser: async (tokenPredefined?: string) => {
        const token = tokenPredefined?tokenPredefined:((getCookie('token') && getCookie('token') !== 'null') ? getCookie('token') : get().token);
        const userInStore = get().user;
        
        if (!token || token == 'null') {
            set({ user: null });
            await Router.push("/login");
        }
        if (token) {
            const getUser = await requestUser(token);
            set({ user: getUser.user });

            // if (window) {
            //   window.customerId = getUser?.user?.customerId;
            // }
            
            // if (window && window.clarity && getUser?.user?.customerId) {
            //   window.clarity('identify', getUser?.user?.customerId);
            //   window.clarity('set', 'customerId', `${getUser?.user?.customerId}`);
            // }
            // if (window && window.clarity && getUser?.user?.companyId) {
            //   window.clarity('set', 'companyId', `${getUser?.user?.companyId}`);
            // }
            // if (window && window.clarity && getUser?.user?.country) {
            //   window.clarity('set', 'country', getUser?.user?.country);
            // }
            // if (window && window.clarity) {
            //     window.clarity('upgrade', 'force-session-record-for-customers')
            // }

            return;
        }
        set({ user: {} });
        await Router.push("/login");
    },
    emptyUser: async () => {
        set({ user: {} });
    },
    setToken: async (token: string) => {
        set({ token: token });
    },
    getToken: () => {
        return get().token;
    },
    setCurrentLocale: async (locale) => {
        set({ currentLocale: locale });
    },
    updateQuiz: async (data: any, quizId?:any, instrumentyTypeId?: number | null, pushGtagData?: any | null) => {
        set({ errorQuiz: "" });
        const token = getCookie("token") || get().getToken();
        if (token) {
            try {
                let quizUpdateResponse;
                const nextQuestionIndex = get().activeQuestion + 1;
                // @ts-ignore
                const questionsScreensLength = get().groups.length;
                if (nextQuestionIndex === questionsScreensLength) {
                  quizUpdateResponse = await updateQuiz(token, data, quizId,instrumentyTypeId); 
                }
                if (pushGtagData) {
                    pushGtagData({
                        Identifier: get().user?.customerId,
                        companyId: get().user?.companyId,
                        event_category:'onboarding',
                        event: 'quiz_response',
                        stepTitle: 'quiz',
                        questionOrder: get().activeQuestion+1,
                        questionName: get().activeQuestionName,
                    });
                }
                const nextScreen = await get().getNextScreen("quiz");
                const redirectAndSetNextScreen = async (route: string) => {
                    try {
                        const formData = {
                            onboardingStep: nextScreen
                        };
                        const token = getCookie("token") || get().getToken();
                        const instance = axios.create({
                            headers: {
                                "Authorization": `Bearer ${token}`,
                                'Screen-Origin-Url': window?.location?.href
                            }
                        })
                        const response = await instance.put(`${process.env.NEXT_PUBLIC_BE_HOST}${urlUpdateMe}`, { data: formData });
                        await Router.push(route);
                    } catch (e) { }
                }
                if (!quizUpdateResponse?.error) {
                    if (nextQuestionIndex === questionsScreensLength) {
                        const route = onboardingRoutes(nextScreen);
                        if (pushGtagData){
                            await pushGtagData({
                                Identifier: get().user?.customerId,
                                companyId: get().user?.companyId,
                                event_category:'onboarding',
                                event: 'registration_funnel',
                                stepTitle: 'quiz',
                            });
                        }
                        if ( Router.asPath?.includes("/quiz-without-flow")) {
                            Router.push('/user/score-calculation');
                        } else {
                            await redirectAndSetNextScreen(route);
                        }
                        return;
                    } else {
                        set({ activeQuestion: nextQuestionIndex });
                        return;
                    }
                } else {
                    throw "quiz.couldNotSubmit";
                }
            } catch (e) {
                set({ errorQuiz: "quiz.couldNotSubmit" });
            }
        }
    },
    saveQuizOnExit: async (data: any, quizId?:any, instrumentyTypeId?: number | null) => {
        set({ errorQuiz: "" });
        const token = getCookie("token") || get().getToken();
        if (token) {
            try {
                let quizUpdateResponse;
                  const currentQuestionIndex = get().activeQuestion;
                  quizUpdateResponse = await updateQuiz(token, data, quizId, instrumentyTypeId, currentQuestionIndex); 
              
                if (quizUpdateResponse?.error) {
                    throw "quiz.couldNotSubmit"
                }
            } catch (e) {
                set({ errorQuiz: "quiz.couldNotSubmit" });
            }
        }
    },
    getQuiz: async (quizId: number) => {
        const token = getCookie("token") || get().getToken();
        if (token) {
            const quiz = await requestQuiz(token, quizId);
            set({ groups: quiz.groups, quizId: quizId });
            return;
        }
        set({ user: {} });
        await Router.push("/login");
    },
    setActiveQuestion: (param?: number) =>
        set((state: any) => {
            set({ errorQuiz: "" });
            const getGroups = get().groups;
            return {
                activeQuestion:
                    param !== undefined
                        ? param
                        // @ts-ignore
                        : getGroups.length > state.activeQuestion + 1
                        ? state.activeQuestion + 1
                        : state.activeQuestion,
            };
        }),

    setCountriesList: async () => {
        const response = await getCountries();

        if (response.error) {
            set({ errorGeneral: response.error });
        } else {
            set({ countries: response });
            return response;
        }
    },
    setAllLicenses: async (countryCode?:any) => {
        const response = await getAllLicenses(countryCode);

        if (response.error) {
            set({ errorGeneral: response.error });
        } else {
            set({ allLicenses: response });
        }
    },
    setDefaultLicenseStore: async (license?: any) => {
        set({ defaultLicense: "SC" });
    },
    setAllCurrencies: async () => {
        const response = await getAllCurrencies();

        if (response.error) {
            set({ errorGeneral: response.error });
        } else {
            set({ allCurrencies: response });
        }
    },
    setLocalesList: async () => {
        const response = await getLocales();

        if (response.error) {
            set({ errorGeneral: response.error });
        } else {
            set({ locales: response });
        }
    },
    setPlatformDefault: async (countryCode?: string | null) => {
        const response = await getPlatformDefault(
            countryCode ? countryCode : null
        );

        if (response.error) {
            set({ errorGeneral: response.error });
        } else {
            set({
                defaultCountry: response.code,
                countryLicenses: response.licenses,
                defaultCountryOptions: response.options,
            });
        }
    },
    getNextScreen: async (active: string) => {
        const allLicenses = get().allLicenses || [];
        const user = get().user || {};
        const currentLicense = allLicenses.find(
            (license: any) => license.companyId === user?.companyId
        );

        if (currentLicense && currentLicense.screens) {
            const currentScreenIndex = currentLicense.screens.findIndex(
                (screen: any) => screen.screen === active
            );
            const totalScreens = currentLicense.screens.length - 1;

            if (currentScreenIndex < totalScreens) {
                return currentLicense.screens[currentScreenIndex + 1].screen;
            }
        }
        return "";
    },
    getPrevScreen: async (active: string) => {
        const allLicenses = get().allLicenses || [];
        const user = get().user || {};
        const currentLicense = allLicenses.find(
            (license: any) => license.companyId === user?.companyId
        );

        if (currentLicense && currentLicense.screens) {
            const currentScreenIndex = currentLicense.screens.findIndex(
                (screen: any) => screen.screen === active
            );

            if (currentScreenIndex > 0) {
                return currentLicense.screens[currentScreenIndex - 1].screen;
            }
        }
        
        return "";
    },
    setCurrentScreenName: (screenName: string) => {
        set({ currentScreenName: screenName });
    },
    setActiveQuestionName: (questionName: string) => {
       set({ activeQuestionName: questionName });
    },
    setIsInsidePlatform: (status: boolean) => {
       set({ isInsidePlatform: status });
    },
    setIsSubmitting(status) {
        set({ isSubmitting: status });
    },
    getGlobalConfigurations: async () => {
        const response = await axios.get(`${process.env.NEXT_PUBLIC_BE_HOST}${urlGlobalConfiguration}`);
        const configurations = response?.data?.data?.attributes;
        set({ globalConfigurations: configurations})
    },
    setQuizTemporary: (data: any, quizId?: any, instrumentyTypeId?: any) => {
        set((prevState: any) => ({
          quizTemporary: {
            data: {...prevState.quizTemporary.data, ...data},
            quizId: quizId || prevState.quizTemporary.quizId,
            instrumentyTypeId: instrumentyTypeId || prevState.quizTemporary.instrumentyTypeId
          }
        }));
      }
}));

export default useStore;
